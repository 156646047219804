import consumer from './consumer';
import $ from 'jquery';

$(document).ready(function () {
  consumer.subscriptions.create({
    channel: 'DownloadCsvChannel',
  }, {
    received(data) {
      const download_responses_btn = $('#download-responses');
      const download_participation_btn = $('#download-participation');
      const spinner = '<div class="spinner-border text-primary" role="status"><span class="sr-only">loading...</span></div> Downloading...';

      $('#export-message').html(data.message);

      if (data.message.indexOf('Download complete.') !== -1) {
        download_responses_btn.html('Download CSV');
        download_responses_btn.attr('disabled', false);
        let link = document.createElement('a');
        link.download = '';
        link.href = data.download_url;
        link.style.display = 'none';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } else {
        download_participation_btn.attr('disabled', true);
        download_responses_btn.attr('disabled', true);
        download_responses_btn.html(spinner);
      }
    },
  });
});
