// Expose jQuery as `$` and `jQuery`.
import $ from 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';
import * as jQueryUi from 'jquery-ui';
import * as jQueryUjs from 'jquery-ujs';
import {sortable} from 'jquery-ui/ui/widgets/sortable';
import * as jQueryUITouchPunch from 'jquery-ui-touch-punch';
import * as bootstrap from 'bootstrap';
import {select2} from 'select2';
import * as jscolorPicker from 'jscolor-picker';
import * as moment from 'moment';
import * as modal from 'bootstrap/js/modal';
import * as tooltip from 'bootstrap/js/tooltip';
import * as popover from 'bootstrap/js/popover';
import * as dropzone from 'dropzone';
import * as dateRangePicker from 'bootstrap-daterangepicker';

import '../channels';

dropzone.autoDiscover = false;

// takes a camelCase string and converts it to snake-case
function camelCaseToSnakeCase(string) {
  return string.replace(/([A-Z])/g, function (str, m1) {
    return '_' + m1.toLowerCase();
  }).replace(/^ms_/, '_ms_');
}

$(document).ready(function () {
  if ($('#list_cues').length > 0) {
    var url = $('#list_cues').attr('url');
    var params = new Object();
    Object.keys($('#list_cues').data()).forEach(function (index) {
      params[camelCaseToSnakeCase(index)] = $('#list_cues').data(index);
    });

    $.ajax({
      method: 'GET',
      url: url,
      dataType: 'script',
      data: params,
    });
  }

  $('.simple-select2').select2({
    width: '100%',
    placeholder: 'Please select',
    allowClear: true,
  });

  $('.simple-select-api').select2({
    ajax: {
      url: '/api/search',
      data: function (params) {
        var query = {
          search: params.term,
          web: 1,
          model_name: $(this).attr('data-model'),
          action_name: $(this).attr('data-action'),
          id_reference: $(this).attr('data-id-reference'),
          scoped_model: $(this).attr('data-scoped-model'),
          page: params.page || 1,
        };
        return query;
      },
    },
    width: '100%',
  });

  $('.simple-select-api').each(function () {
    var id = $(this).attr('id');
    var selected = [];
    $('#' + id + ' option').each(function () {
      if ($(this).val()) {
        selected.push($(this).val());
      }
    });

    $('#' + id).
      val(selected).
      trigger('change');
  });

  $('.js-popup-demo').click(function () {
    var link, openWindow;
    link = $(this).attr('href');
    openWindow = window.open(link, '', 'width=375, height=667');
    openWindow.opener = null;
    return false;
  });

  var messageForValueSelected = function (element) {
    if (element.val()) {
      var message = element.attr('data-message');
      var value = element.attr('data-value').toLowerCase();
      if (value === element.val().toLowerCase()) {
        alert(message);
      }
    }
  };

  messageForValueSelected($('.message-for-value'));

  $('.message-for-value').change(function () {
    messageForValueSelected($('.message-for-value'));
  });
});

export {
  $,
  sortable,
  modal,
  tooltip,
  popover,
  jQueryUi,
  jQueryUjs,
  jQueryUITouchPunch,
  bootstrap,
  jscolorPicker,
  dropzone,
  dateRangePicker,
  select2,
};
